<template>
  <div class="news">
    <div class="news-container" style="padding: 3rem 0rem 3rem">
      <div class="container is-fluid">
        <div
          style="font-size: 2em; text-align: center; padding: 0rem 1.5rem 3rem"
        >
          What's New
        </div>
        <div class="columns is-multiline">
          <div
            class="column is-full"
            v-for="(item, index) in newsList"
            :key="index"
          >
            <div class="card">
              <!-- <div class="card-image">
                <figure class="image is-4by3">
                  <img :src="item.img" alt="Placeholder image" />
                </figure>
              </div> -->
              <div
                class="card-content"
                style="background-color: #f5f5f5; border-radius: 10px"
              >
                <div class="content">
                  <time datetime="2020-9-8">{{ item.date }}</time>
                  <div style="font-weight: 600; padding: 2.5px 0px 10px 0px">
                    {{ item.title }}
                  </div>
                  <div style="white-space: pre-line">{{ item.details }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { NewsItem } from "@/store/types";
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";

const SystemModule = namespace("system");

@Options({
  components: {},
})
export default class News extends Vue {
  public async mounted(): Promise<void> {
    window.scrollTo(0, 0);
    await this.updatePageLoadingState(false);
    await this.getNewsList();
  }

  @SystemModule.Action("updatePageLoadingState")
  private updatePageLoadingState!: (state: boolean) => Promise<void>;

  @SystemModule.Action("getNewsList")
  private getNewsList!: () => Promise<void>;

  @SystemModule.Getter("newsList")
  private newsList!: NewsItem[];
}
</script>
<style lang="scss">
.news {
  padding-top: 3.25rem;

  .news-container {
    padding: 10px;

    .card {
      box-shadow: none;

      .card-image:first-child img {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
    }
  }
}
</style>
