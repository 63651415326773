
import { NewsItem } from "@/store/types";
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";

const SystemModule = namespace("system");

@Options({
  components: {},
})
export default class News extends Vue {
  public async mounted(): Promise<void> {
    window.scrollTo(0, 0);
    await this.updatePageLoadingState(false);
    await this.getNewsList();
  }

  @SystemModule.Action("updatePageLoadingState")
  private updatePageLoadingState!: (state: boolean) => Promise<void>;

  @SystemModule.Action("getNewsList")
  private getNewsList!: () => Promise<void>;

  @SystemModule.Getter("newsList")
  private newsList!: NewsItem[];
}
